import React from "react";
import { Link } from "gatsby";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import pinterest from "../img/social/pinterest.svg";
import * as LogoImg from "../../static/img/logo-update.png";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item navbar-logo" title="Logo">
              <img
                src={LogoImg}
                alt="Cannon Custom Woodworking"
                className="logo"
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <Link to="/" title="Logo">
              <img
                src="../img/logo-update.png"
                alt="Cannon Custom Woodworking"
                className="mobile-logo"
              />
            </Link>

            <div className="navbar-start has-text-centered">
              <Link className="navbar-item page-link" to="/about">
                About
              </Link>
              <Link className="navbar-item page-link has-children" to="/products">
                Products
                {/* <div className="sub-nav">
                  Hidden sub-nav menu things.
                </div> */}
              </Link>
              <Link className="navbar-item page-link" to="/blog">
                Blog
              </Link>
              <Link className="navbar-item page-link" to="/contact">
                Contact
              </Link>
            </div>
            <div className="navbar-end has-text-centered navbar-icons">
              <a href="https://www.facebook.com/CannonCraftWoodworking/" target="_blank" className="icon">
                <img src={facebook} alt="Cannon Custom Woodworking Facebook" />
              </a>
              <a href="https://www.instagram.com/cannon_custom/" target="_blank" className="icon">
                <img src={instagram} alt="Cannon Custom Woodworking Instagram" />
              </a>
              <a href="https://www.pinterest.com/cannon_custom_woodworking/" target="_blank" className="icon">
                <img src={pinterest} alt="Cannon Custom Woodworking Instagram" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;